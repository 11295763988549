import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-fluid" }
const _hoisted_2 = {
  class: "p-fluid p-formgrid p-grid",
  style: {"alignment":"center"}
}
const _hoisted_3 = {
  class: "p-field p-col-3 p-md-3",
  style: {"margin-bottom":"1px","padding-bottom":"1px"}
}
const _hoisted_4 = { style: {"font-size":"18px","font-weight":"bolder","text-transform":"uppercase"} }
const _hoisted_5 = { class: "p-field p-col-12 p-md-12" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, { style: {"text-align":"center","alignment":"center"} }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.listVideo, (lst) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Button, {
                label: "Xóa",
                icon: "pi pi-trash",
                class: "p-button-outlined",
                onClick: ($event: any) => ($setup.PlayVideo(lst.id))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("label", _hoisted_4, _toDisplayString(lst.title), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]))
          }), 256)),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "p-field p-col-12 p-md-12" }, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(), _createElementBlock("video", {
              width: "1400",
              controls: "",
              key: $setup.link,
              autoplay: "",
              loop: ""
            }, [
              _createElementVNode("source", {
                src: $setup.link,
                type: "video/mp4"
              }, null, 8, _hoisted_6)
            ]))
          ])
        ])
      ])
    ]),
    _: 1
  }))
}