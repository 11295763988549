import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-fluid" }
const _hoisted_2 = { class: "p-fluid p-formgrid p-grid" }
const _hoisted_3 = {
  class: "p-field p-col-12 p-sm-12 p-md-12",
  style: {"alignment":"left"}
}
const _hoisted_4 = { class: "p-fluid" }
const _hoisted_5 = { class: "p-fluid p-formgrid p-grid" }
const _hoisted_6 = { class: "p-field p-col p-col-12 p-md-6 p-lg-3" }
const _hoisted_7 = { class: "p-field p-col p-col-12 p-md-6 p-lg-3" }
const _hoisted_8 = { class: "card flex justify-content-center flex-wrap gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Panel, { header: "PHIẾU KHẢO SÁT BỆNH NHÂN RA VIỆN" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "p-field p-col p-col-12 p-md-12 p-lg-12" }, [
              _createElementVNode("label", null, "Chúng tôi xin chân thành cảm ơn Quý bệnh nhân đã sử dụng các dịch vụ khám bệnh, chữa bệnh tại Bệnh viện Y học cổ truyền Đà Nẵng. Với mong muốn ngày càng nâng cao chất lượng dịch vụ và mang đến sự hài lòng hơn cho người bệnh, xin Quý Ông/ Bà dành chút thời gian quý báu để đánh giá về chất lượng chăm sóc và điều trị tại Bệnh viện.")
            ], -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_Button, {
                      name: "checkbox",
                      severity: "info",
                      label: "Hài lòng",
                      icon: "pi pi-check",
                      class: "background",
                      value: false,
                      modelValue: $setup.gladdening,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.gladdening) = $event)),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.evalClick(1))),
                      style: {"alignment":"center","margin-right":"10px"}
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_Button, {
                        name: "checkbox",
                        label: "Không hài lòng",
                        icon: "pi pi-times",
                        severity: "danger",
                        raised: "",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.evalClick(0)))
                      })
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_ConfirmPopup)
  ], 64))
}