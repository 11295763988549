

import {onMounted, ref} from 'vue';
import { computed} from 'vue'
import {EvalTitle} from "@/models/evalTitle.models";
import AuthRepository from "@/services/AuthRepository";
import {EvalPatient} from "@/models/evalPatient";
import EvaluationRepository from "@/services/EvaluationRepository";
import {useConfirm} from "primevue/useconfirm";
import {useToast} from "primevue/usetoast";
import {PatientInfo} from "@/models/patientInfo";
import Button from 'primevue/button';

export default {
  setup() {
      const evals = ref([{ "label": "Rất không hài lòng hoặc: Rất kém","label1": "Không hài lòng hoặc: Kém","label2": "Bình thường hoặc: Trung bình","label3": "Hài lòng hoặc: Tốt","label4": "Rất hài lòng hoặc: Rất tốt"}]);

      const listQues = ref([
          {label:'Cơ sở khác chuyển đến;', value: 1},
          {label:'Thông tin trên Mạng xã hội, Website, tivi, báo...;', value: 2},
          {label:'Bạn bè, người thân giới thiệu;', value: 3},
          {label:'Bản thân/ người thân từng điều trị ở Bệnh viện;', value: 4},
          {label:'Theo lịch hẹn khám chữa bệnh (phiếu hẹn, điện thoại);', value: 5},
          {label:'Mục khác:', value: 6},
      ]);
    const listStatus = ref([
      {label:'1. Ông/Bà vui lòng đánh giá thái độ phục vụ của Bác sĩ. Có được Bác sĩ thăm khám hằng ngày.', value: 1, param: 0},
      {label:'2. Ông/Bà vui lòng đánh giá thái độ phục vụ của Điều dưỡng.', value: 2, param: 0},
      {label:'3. Ông/Bà vui lòng đánh giá về hướng dẫn thủ tục vào viện (tại Phòng bảo vệ, khoa khám bệnh, Phòng thu viện phí, Khoa điều trị trực tiếp).', value: 3, param: 0},
      {label:'4. Ông/Bà có được nhân viên y tế tư vấn về chế độ ăn bệnh lý, cách uống thuốc, vận động tránh té ngã và theo dõi phòng ngừa biến chứng.', value: 4, param: 0},
      {label:'5. Ông/Bà có được Bác sĩ giải thích về tình trạng bệnh trước khi làm xét nghiệm và dịch vụ y tế ngoài bảo hiểm.', value: 5, param: 0},
      {label:'6. Ông/Bà đánh giá về hiệu quả điều trị trong thời gian điều tri tại Bệnh viện.', value: 6, param: 0},
      {label:'7. Ông/Bà vui lòng đánh giá về vệ sinh của Buồng bệnh, nhà vệ sinh...', value: 7, param: 0},
      {label:'8. Ông/Bà vui lòng đánh giá về thủ tục xuất viện (tại Khoa điều trị, Phòng viện phí).', value: 8, param: 0},
      {label:'9. Ông/Bà vui lòng đánh giá về hiệu quả điều trị trong quá trình điều trị tại Bệnh viện', value: 9, param: 0},
    ]);
    const listComeback = ref([
      {label:'Không bao giờ quay lại.', value: 1},
      {label:'Có thể sẽ quay lại', value: 2},
      {label:'Chắc chắn sẽ quay lại hoặc giới thiệu cho người khác', value: 3},
      {label:'Mục khác:', value: 4},
    ]);
      const percent = ref();
      const listEval = ref([] as EvalTitle[]);
      const evalPatient = ref({} as EvalPatient);
      const danhgia = ref("");
      const diemso = ref(0);
      const toast = useToast();
      const loadingBar = ref(false);
      const patient = ref({} as PatientInfo);
      const idStatus = ref(0);
      const comeBack = ref(0);
      const hailong= ref("");
      const date = ref("");
      const gladdening = ref(false);

      const confirm = useConfirm();

      const reset = () => {
          const temp = ref({} as EvalPatient);
          listEval.value = [];
          evalPatient.value = temp.value;
          danhgia.value = "";
          diemso.value = 0;
      }
      const confirmSuccess = () => {
          reset();
          toast.add({
              severity: 'success',
              summary: 'Đánh giá thành công',
              detail: 'Cảm ơn quý khách đã đánh giá, hi vọng quý khách đã có trải nghiệm tốt tại bệnh viện, xin cảm ơn',
              life: 4000
          });
      };

        const evalClick = (id: number) =>{
            // console.log("##################$$$$$$$$$$$$$$$$$$$$ evalClick listEval: " + JSON.stringify(listEval.value));
            //listEval.value.forEach(x => {x.listques.forEach(y => {danhgia.value = danhgia.value + y.ma + " - " + y.xeploai + "|"; diemso.value = diemso.value + y.xeploai;})});
          evalPatient.value.question2 = "";
          evalPatient.value.question1 = "";
          evalPatient.value.question3 = "";
          // listStatus.value.forEach(x => evalPatient.value.question2 = evalPatient.value.question2 + x.value + " - " + x.param + "|");

            console.log("##################$$$$$$$$$$$$$$$$$$$$ evalClick listEval evalPatient: " + JSON.stringify(evalPatient.value));
            if(id == 1) {
              evalPatient.value.score = 100;
              EvaluationRepository.createEvalPatient(evalPatient.value)
                  .then((response) => {
                    console.log("##################$$$$$$$$$$$$$$$$$$$$ evalClick listEval response: " + JSON.stringify(response.data));
                    confirmSuccess();
                    const temp = ref({} as EvalPatient);
                    evalPatient.value = temp.value;
                    listStatus.value.forEach(x => x.param = 0);
                  })
                  .catch()
                  .finally(
                      //function(){
                      //setTimeout(loadList, 1 * 1000);}
                  );
            }
            else {
              toast.add({
                severity: 'error',
                summary: 'Đánh giá thành công',
                detail: 'Xin lỗi vì những trải nghiệm không tốt của quý khách, bệnh viện luôn lắng nghe phản hồi từ quý khách để phục vụ quý khách được tốt hơn. Xin trân trọng cảm ơn',
                life: 10000
              });
              evalPatient.value.score = 0;
              EvaluationRepository.createEvalPatient(evalPatient.value)
                  .then((response) => {
                    const temp = ref({} as EvalPatient);
                    evalPatient.value = temp.value;
                    listStatus.value.forEach(x => x.param = 0);
                  })
                  .catch()
                  .finally(
                      //function(){
                      //setTimeout(loadList, 1 * 1000);}
                  );
            }
        };

    const valid = computed(() => {
      console.log("##########AAAAAA listStatus: " + JSON.stringify(listStatus.value));
      console.log("##########AAAAAA checkQuestionSelect: " + checkQuestionSelect());
      return evalPatient.value.department&&evalPatient.value.roomNumber&&evalPatient.value.question1&&checkQuestionSelect()&&evalPatient.value.question3;
    });
    const checkQuestionSelect = () => {
      const temp = listStatus.value.filter(x => (x.param == 0));
      if(temp.length == 0)
      {
        return true;
      }
      else
      {
        return false;
      }
    }

    return {
        listEval,
        listQues,
        percent,
        evalPatient,
        evalClick,
        evals,
        loadingBar,
      listStatus,
      idStatus,
      listComeback,
      comeBack,
      hailong,
      date,
      valid,
      gladdening
    }
  }

}
